<template>
	<div class="page">
		<div class="errorText">
			<div v-if="isError">{{errorText}}</div>
			<div v-if="voiceErr">{{voiceErrText}}</div>
		</div>
		<!--视频组件容器-->
		<div class="content">
			<!--左边成员列表名单-->
			<div class="left" :class="isError || voiceErr?'errorBack':''">
				<div id="warp" class="userlist localStream">
					<!--本地流-->
					<div id='local_stream' class="local-stream">
					</div>
				</div>

				<div class="mask" v-if="isReload" @click="reload">
					<div class="txt">点击重试</div>
				</div>
			</div>
			<!-- <div class="savePhoto"><van-button type="default" @click="takePhoto">截图</van-button></div> -->
			<canvas ref="canvas" width="400" height="550" style="display: none;"></canvas>
		</div>

		<div class="bottomBox">
			<div class="textBox">
				<div v-if="isVoice == ''">
					<div class="txt">{{voicePromptContent}}</div>
				</div>
				<div v-else>
					<div class="txt tit" v-if="moreVerifyWay == '0'">请用普通话大声朗读以下文本：</div>
					<div class="txt tit" v-if="moreVerifyWay == '1'">请在语音播报完以下问题后如实回答：</div>
					<div class="txt" v-if="moreVerifyWay == '0'">{{readContent}}</div>
					<div class="txt" v-if="moreVerifyWay == '1'">{{question}}</div>
				</div>
				<div class="voiceBox" v-if="isVoice == 1">
					<div>语音播报中</div>
					<img src="../../src/static/img/voice.png" alt="">
				</div>
				<div class="voiceBox" v-if="isVoice == 2">
					<div>语音识别中</div>
					<img src="../../src/static/img/voiceing.png" alt="">
				</div>
				<div class="voiceBox" v-if="isVoice == 3">
					<div>语音完成</div>
					<img src="../../src/static/img/voicend.png" alt="">
				</div>
				<div class="progressBox">
					<div class="progress" v-show="isVoice == 2">
						<div class="progressLine" id="progressLine" :style="nowProgress == 0 ? 'width:0':''"></div>
					</div>
				</div>
			</div>
		</div>
		<audio id="audio" ref="audio" playsinline></audio>
	</div>
</template>

<script>
	import TRTC from 'trtc-js-sdk'
	import '../static/asr/examples/trtc/asr.js'
	export default {
		data() {
			return {
				userInfo: '',
				roomId: '', // 房间号--加入相同房间才能聊
				localStream: '', // 本地流,
				localStreamAsr: '',
				context2d: null,
				canvas: null,
				videoID: '',
				voice_text_str: '',
				isVoice: '',
				isFirst: true,
				isError: false,
				errorText: '',
				auto: null,
				readContent: '',
				voicePromptContent: '',
				voiceErr: false,
				verifyWay: '',
				moreVerifyWay: '',
				question: '',
				isReload: false,
				isOnce: true,
				isVoiceOver: false,
				isFirstAdd: true,
				isLast: false,
				isClick: true, //防止多次点击
				isDestroyed: false,
				isMinute: null,
				audio1: null,
				audio2: null,
				audio3: null,
				step: 1,
				isAsrStop: false,
				isPlay: true,
				isSystemCheck: true,
				isCheckPhoto: true,
				isCheckVoice: true,
				asrKeyInfo: '',
				asrTimeDown: null,
				nowProgress: 100
			}
		},
		created() {
			TRTC.checkSystemRequirements().then((checkResult) => {
				if (!checkResult.result) {
					console.log('checkResult', checkResult.result, 'checkDetail', checkResult.detail);
					// SDK 不支持当前浏览器，根据用户设备类型建议用户使用 SDK 支持的浏览器
					this.isSystemCheck = false
					this.$dialog.alert({
						title: '提示',
						message: '当前浏览器不支持，请更换浏览器',
						showConfirmButton: false,
						showCancelButton: false
					})
				}
			});
		},
		mounted() {
			const that = this
			if (!that.isSystemCheck) {
				return false
			}
			let u = navigator.userAgent
			let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
			that.audio1 = document.getElementById('audio')
			that.audio2 = document.createElement('audio')
			that.audio3 = document.createElement('audio')
			if (isIos) {
				var ua = navigator.userAgent.toLowerCase()
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					//在微信中打开
				} else {
					document.addEventListener('touchstart', function(e) {
						if (that.isPlay) {
							that.audio1.play()
							that.audio1.pause()
							that.audio2.play()
							that.audio2.pause()
							that.audio3.play()
							that.audio3.pause()
							that.isPlay = false
						}
					}, false)
				}
			}
			if (this.$route.query.token) {
				sessionStorage.setItem('token', 'Bearer ' + this.$route.query.token)
			}

			if (this.$route.query.from == 'home') {
				this.$router.push({
					query: {
						from: ''
					}
				})
				if (sessionStorage.getItem('userInfo')) {
					this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
					document.title = this.userInfo.title
					this.readContent = this.userInfo.readContent
					this.question = this.userInfo.question
					this.verifyWay = this.userInfo.verifyWay
					if (this.userInfo.voicePromptContent == '') {
						this.isCheckVoice = false
						this.isVoice = '1'
					}
					if (this.userInfo.faceThan == 0 && this.userInfo.securityThan == 0) {
						this.isCheckPhoto = false
					}
					this.voicePromptContent = this.userInfo.voicePromptContent
					if (this.verifyWay.length > 1) {
						this.moreVerifyWay = this.verifyWay.split(',')[0]
					} else {
						this.moreVerifyWay = this.verifyWay
					}
					this.roomId = this.userInfo.appId + '_' + new Date().getTime()
					this.getAsrKeyInit()
					if (isIos) {
						var ua = navigator.userAgent.toLowerCase()
						if (ua.match(/MicroMessenger/i) == "micromessenger") {
							//在微信中打开
							that.getVoiceInit()
							setTimeout(() => {
								that.trtcInit()
							}, 1000)
						} else {
							that.$dialog.alert({
								title: '欢迎使用',
								message: '欢迎使用' + that.userInfo.title
							}).then(() => {
								that.getVoiceInit()
								setTimeout(() => {
									that.trtcInit()
								}, 1000)
							})
						}
					} else {
						that.getVoiceInit()
						setTimeout(() => {
							that.trtcInit()
						}, 1000)
					}
				} else {
					this.getAppInit()
				}
			} else {
				this.getAppInit()
			}

			this.context2d = this.$refs.canvas.getContext('2d')
			this.canvas = this.$refs.canvas

		},
		destroyed() {
			clearInterval(this.auto)
			clearInterval(this.isMinute)
			clearInterval(this.asrTimeDown)
			this.audio1.src = ''
			this.audio2.src = ''
			this.audio3.src = ''
			this.isDestroyed = true
			this.localStream.stop();
			this.localStream.close();
			this.localStream = null
			this.client.leave()
			this.localStreamAsr.stop();
			console.log('销毁监听')
		},
		methods: {
			getAppInit() {
				const that = this
				let u = navigator.userAgent
				let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
				let params = {
					dateTime: new Date().getTime()
				}
				params.sign = this.util.paramsFormat(params, 'feioou')
				this.api.appInit(params).then(res => {
					if (res.code == 200) {
						document.title = res.data.title
						sessionStorage.setItem('userInfo', JSON.stringify(res.data))
						this.userInfo = res.data
						this.readContent = this.userInfo.readContent
						this.question = this.userInfo.question
						this.verifyWay = this.userInfo.verifyWay
						if (this.userInfo.voicePromptContent == '') {
							this.isCheckVoice = false
							this.isVoice = '1'
						}
						if (this.userInfo.faceThan == 0 && this.userInfo.securityThan == 0) {
							this.isCheckPhoto = false
						}
						this.voicePromptContent = this.userInfo.voicePromptContent
						if (this.verifyWay.length > 1) {
							this.moreVerifyWay = this.verifyWay.split(',')[0]
						} else {
							this.moreVerifyWay = this.verifyWay
						}
						this.roomId = this.userInfo.appId + '_' + new Date().getTime()
						this.getAsrKeyInit()

						if (isIos) {
							var ua = navigator.userAgent.toLowerCase()
							if (ua.match(/MicroMessenger/i) == "micromessenger") {
								//在微信中打开
								that.getVoiceInit()
								setTimeout(() => {
									that.trtcInit()
								}, 1000)
							} else {
								that.$dialog.alert({
									title: '欢迎使用',
									message: '欢迎使用' + that.userInfo.title
								}).then(() => {
									that.getVoiceInit()
									setTimeout(() => {
										that.trtcInit()
									}, 1000)
								})
							}
						} else {
							that.getVoiceInit()
							setTimeout(() => {
								that.trtcInit()
							}, 1000)
						}
					}
				}).catch(err => {
					this.$dialog({
						title: '提示',
						message: err.data.message,
						className: 'dialogTxt',
						showConfirmButton: false,
						showCancelButton: false
					})
				})
			},
			getVoiceInit() {
				const that = this
				//获取前言语音
				if (this.isCheckVoice) {
					let params = {
						dateTime: new Date().getTime()
					}
					params.sign = this.util.paramsFormat(params, this.userInfo.secretKey)
					this.api.getVoiceSrc(params).then(res => {
						console.log('语音播报初始化')
						that.audio1.src = 'data:audio/wav;base64,' + res.data
						this.isVoice = ''
						that.audio1.muted = true
						if (window.WeixinJSBridge) {
							WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
								that.audio1.play();
							}, false);
						} else {
							document.addEventListener("WeixinJSBridgeReady", function() {
								WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
									that.audio1.play();
								});
							}, false);
						}
						that.audio1.play();
						that.audio1.muted = true
						that.volume = 0

						let u = navigator.userAgent
						let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
						if(isIos){
							setTimeout(()=>{
								that.audio1.pause()
							},1000)
						}
					})
				} else {
					that.isVoiceOver = true
					that.isFirstAdd = false
					that.step = 2
				}

				//获取朗读或问答语音
				if (this.verifyWay.length > 1) {
					this.asrInit(this.verifyWay.split(',')[0], '2')
					this.asrInit(this.verifyWay.split(',')[1], '3')
				} else {
					this.asrInit(this.verifyWay, '1')
				}
			},
			voicePlay() {
				const that = this
				if (this.isCheckVoice) {
					let audio1 = that.audio1
					that.audio2.pause()
					that.audio3.pause()
					audio1.pause()
					audio1.currentTime = 0
					audio1.muted = false
					if (window.WeixinJSBridge) {
						WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
							audio1.play();
						}, false);
					} else {
						document.addEventListener("WeixinJSBridgeReady", function() {
							WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
								audio1.play();
							});
						}, false);
					}
					audio1.play();
					audio1.volume = 1.0
					audio1.onended = function() {
						console.log('语音播报完成')
						if (that.isFirstAdd) {
							that.isVoiceOver = true
							that.isFirstAdd = false
							that.step = 2
						}
						if (!that.isCheckPhoto) {
							if (that.isVoiceOver) {
								if (that.isFirst) {
									that.isFirst = false
									if (that.step == 2) {
										that.isVoice = 1
										let audio = that.audio2
										audio.pause()
										audio.muted = false
										audio.currentTime = 0
										setTimeout(() => {
											if (window.WeixinJSBridge) {
												WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
													audio.play()
												}, false);
											} else {
												document.addEventListener("WeixinJSBridgeReady", function() {
													WeixinJSBridge.invoke('getNetworkType', {},
														function(e) {
															audio.play()
														});
												}, false);
											}
											audio.play()
											audio.volume = 1.0
											audio.onended = function() {
												console.log('播放完成step2')
												if (that.isOnce) {
													that.createAsr()
													that.isOnce = false
												}
											}
										}, 1000)
									}
								}
							}
						}
					}
				} else {
					that.isVoiceOver = true
					that.isFirstAdd = false
					that.step = 2
					if (!that.isCheckPhoto) {
						that.isFirst = false
						let audio2 = that.audio2
						audio2.pause();
						audio2.currentTime = 0
						audio2.muted = false
						setTimeout(() => {
							if (window.WeixinJSBridge) {
								WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
									audio2.play();
								}, false);
							} else {
								document.addEventListener("WeixinJSBridgeReady", function() {
									WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
										audio2.play();
									});
								}, false);
							}
							audio2.play();
							audio2.volume = 1.0
							audio2.onended = function() {
								console.log('播放完成')
								if (that.isOnce) {
									that.createAsr()
									that.isOnce = false
								}
							}
						}, 1500)
					}

				}
			},
			trtcInit() {
				let params = {
					roomId: this.roomId,
					dateTime: new Date().getTime()
				}
				params.sign = this.util.paramsFormat(params, this.userInfo.secretKey)
				this.api.trtcInit(params).then(res => {
					if (res.code == 200) {
						let data = res.data
						this.createClient(data)
					}
				}).catch(err => {
					if (err.data.code == 1900022) {
						this.$router.push({
							path: '/result',
							query: {
								status: 'error'
							}
						})
					}
				})
			},
			// 创建链接
			createClient(info) {
				// 获取签名
				const sdkAppId = Number(info.sdkAppId)
				const userSig = info.userSig
				const userId = info.userId
				this.client = TRTC.createClient({
					mode: 'rtc',
					sdkAppId,
					userId,
					userSig,
					useStringRoomId: true
				})
				// 初始化后才能加入房间
				this.joinRoom()
			},
			// 加入房间
			async joinRoom() {
				await this.client.join({
						roomId: this.roomId
					}) // Number(this.videoInfo.roomNumber)
					.then(() => {
						// 创建本地流
						this.createStream()
					})
					.catch((error) => {
						console.error('进房失败 ' + error)
					})
			},
			// 创建本地音视频流
			createStream() {
				navigator.mediaDevices.getUserMedia({
					video: true,
					audio: {
						volume: 1.0,
						sampleSize: 16,
						sampleRate: 44100,
						echoCancellation: false,
						noiseSuppression: false
					}
				}).then(stream => {
					const audioTrack = stream.getAudioTracks()[0];
					const videoTrack = stream.getVideoTracks()[0];
					// 对audioTrack和videoTrack处理之后，
					this.localStream = TRTC.createStream({
						userId: this.userId,
						audioSource: audioTrack,
						videoSource: videoTrack
					});
					this.localStream.initialize().then(() => {
						this.localStream.play('local_stream')
						this.publishStream()
						// 本地流初始化成功，可通过Client.publish(localStream)发布本地音视频流
					}).catch(error => {
						console.error('failed initialize localStream ' + error);
					});
				});
			},
			// 发布本地音视频流
			publishStream() {
				const that = this
				this.client.publish(this.localStream)
					.catch((error) => {
						console.error('本地流发布失败 ' + error)
					})
					.then(() => {
						this.videoID = 'video_' + this.localStream.id_
						console.log('本地流发布成功')
						// this.getVoiceInit()
						this.voicePlay()
						setTimeout(() => {
							that.takePhoto()
						}, 2000)
					})
			},

			takePhoto() {
				if (!this.isCheckPhoto) {
					return false
				}
				if (this.isDestroyed) {
					return false
				}
				let video = document.getElementById(this.videoID)
				this.context2d.drawImage(video, 0, 0, 400, 550)
				const that = this
				let img = new Image()
				img.src = this.canvas.toDataURL('image/png')
				let canvas = document.createElement('canvas')
				let ctx = canvas.getContext('2d')
				let createW = document.createAttribute('width')
				let createH = document.createAttribute('height')
				createW.nodeValue = 320
				createH.nodeValue = 360
				canvas.setAttributeNode(createW)
				canvas.setAttributeNode(createH)
				let clientW = document.body.clientWidth
				let offsetW = ((clientW - 320) / 2)
				let base64Result = ''
				var num = 0
				img.onload = function() {
					ctx.drawImage(img, offsetW, 100, 320, 360, 0, 0, 320, 360)
					base64Result = canvas.toDataURL('image/png', 1)
					that.compress(base64Result, function(ysImg) {
						let params = {
							dateTime: new Date().getTime()
						}
						params.sign = that.util.paramsFormat(params, that.userInfo.secretKey)
						params.nowImage = ysImg.split(',')[1]
						that.api.checkPeople(params).then(res => {
							if (res.code == 200) {
								that.auto = setInterval(() => {
									if (num < 2) {
										num++
									}
									if (num == 2 && !that.isError && !that.voiceErr) {
										that.takePhoto()
										num = 0
										clearInterval(that.auto)
									}
								}, 1000)
								if (!that.voiceErr) {
									that.isReload = false
								}
								if (res.data.error_code == 0) {
									if (res.data == null) {
										if (!that.isLast) {
											that.isError = true
											that.errorText = '人脸识别失败'
										}
										return false
									}
									if (that.isVoiceOver) {
										if (that.isFirst) {
											that.isFirst = false
											if (that.step == 2) {
												that.isVoice = 1
												let audio2 = that.audio2
												audio2.pause();
												audio2.currentTime = 0
												audio2.muted = false
												setTimeout(() => {
													if (window.WeixinJSBridge) {
														WeixinJSBridge.invoke(
															'getNetworkType', {},
															function(
																e) {
																audio2.play();
															}, false);
													} else {
														document.addEventListener(
															"WeixinJSBridgeReady",
															function() {
																WeixinJSBridge.invoke(
																	'getNetworkType', {},
																	function(e) {
																		audio2.play();
																	});
															}, false);
													}
													audio2.play();
													audio2.volume = 1.0
													audio2.onended = function() {
														console.log('播放完成')
														if (that.isOnce) {
															that.createAsr()
															that.isOnce = false
														}
													}
												}, 1000)
											}
										}
									}
									that.isError = false
								} else {
									if (that.isLast) {
										return false
									}
									if (res.data.error_code == 1900018) {
										that.errorText = '人脸比对失败'
									} else if (res.data.error_code == 1900013) {
										that.errorText = '非办理人录制'
									} else {
										that.errorText = '未检测到完整人脸'
									}
									if (that.isVoiceOver) {
										that.isVoice = 1
									}
									that.isError = true
									that.isReload = true
									if (!that.isMinute) {
										that.errorCountDown()
									}
									that.pause()
									clearInterval(that.auto)
									if (that.localStreamAsr) {
										that.isAsrStop = true
										that.localStreamAsr.stop()
										clearInterval(that.asrTimeDown)
										that.asrTimeDown = null
									}
								}
							}
						}).catch(err => {
							if (that.isLast) {
								return false
							}
							if (err.data.code == 1900020 || err.data.code == 1900019 || err.data
								.code == 1900022 || err.data.code == 1900013) {
								that.errorText = err.data.message
								setTimeout(() => {
									that.$router.push({
										path: '/result',
										query: {
											status: 'error'
										}
									})
								}, 1500)
							} else if (err.data.code == 1900018) {
								that.errorText = '人脸比对失败'
							} else {
								that.errorText = '未检测到完整人脸'
							}
							that.isError = true
							if (that.isVoiceOver) {
								that.isVoice = 1
							}
							that.isReload = true
							if (!that.isMinute) {
								that.errorCountDown()
							}
							that.pause()
							clearInterval(that.auto)
							if (that.localStreamAsr) {
								that.isAsrStop = true
								that.localStreamAsr.stop()
								clearInterval(that.asrTimeDown)
								that.asrTimeDown = null
							}
						})
					})
				}
			},
			createAsr() {
				this.localStreamAsr = new ASR({
					secretKey: this.asrKeyInfo.tmpSecretKey,
					secretId: this.asrKeyInfo.tmpSecretId,
					token: this.asrKeyInfo.token,
					appId: 1307285556,
					// 实时识别接口参数
					engine_model_type: '16k_zh', // 引擎
					voice_format: 1,
					// 以下为非必填参数，可跟据业务自行修改
					hotword_id: '08003a00000000000000000000000000',
					needvad: 1,
					filter_dirty: 1,
					filter_modal: 1,
					filter_punc: 1,
					convert_num_mode: 1,
					word_info: 2,
					audioTrack: this.localStream.getAudioTrack() // this.localStream_.getAudioTrack() 为获取的本地流的音轨
				})
				this.localStreamAsr.start();
				console.log('语音识别开始调用')
				this.isAsrStop = false
				this.listenerAsr()
			},
			listenerAsr() {
				const that = this
				this.localStreamAsr.OnRecognitionStart = (res) => {
					console.log('本地流：开始识别', res);
					this.isVoice = 2
					if (!that.asrTimeDown) {
						that.checkAsrTime()
					}
				};

				// 一句话开始
				this.localStreamAsr.OnSentenceBegin = (res) => {
					console.log('本地流：一句话开始', res);
				};

				// 一句话结束
				this.localStreamAsr.OnSentenceEnd = (res) => {
					console.log('本地流：一句话结束', res);
					if (this.isAsrStop) {
						return false
					}
					if (res.voice_text_str != '') {
						this.voice_text_str = res.voice_text_str
						this.checkAsrEnd()
					}
				};

				//识别错误
				this.localStreamAsr.OnError = (res) => {
					console.log('错误信息：', res)
				}
			},

			asrInit(type, num) {
				const that = this
				let params = {
					dateTime: new Date().getTime(),
					type: type
				}
				params.sign = that.util.paramsFormat(params, that.userInfo.secretKey)
				this.api.getAsr(params).then(res => {
					if (res.code == 200) {
						if (num == '1') {
							that.audio2.src = 'data:audio/wav;base64,' + res.data
						} else {
							if (num == '2') {
								that.audio2.src = 'data:audio/wav;base64,' + res.data
							}
							if (num == '3') {
								that.audio3.src = 'data:audio/wav;base64,' + res.data
							}
						}
						if (num == '1' || num == '2') {
							if (that.audio2) {
								that.audio2.muted = true
								if (window.WeixinJSBridge) {
									WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
										that.audio2.play();
									}, false);
								} else {
									document.addEventListener("WeixinJSBridgeReady", function() {
										WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
											that.audio2.play();
										});
									}, false);
								}
								that.audio2.play();
								that.audio2.muted = true
								that.audio2.volume = 0.0
							}
							// if (!that.isCheckVoice && !that.isCheckPhoto) {
							// 	that.isFirst = false
							// 	let audio2 = that.audio2
							// 	audio2.currentTime = 0
							// 	audio2.muted = true
							// 	setTimeout(() => {
							// 		if (window.WeixinJSBridge) {
							// 			WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
							// 				audio2.play();
							// 			}, false);
							// 		} else {
							// 			document.addEventListener("WeixinJSBridgeReady", function() {
							// 				WeixinJSBridge.invoke('getNetworkType', {}, function(
							// 					e) {
							// 					audio2.play();
							// 				});
							// 			}, false);
							// 		}
							// 		audio2.play();
							// 		that.audio2.volume = 0
							// 		audio2.onended = function() {
							// 			console.log('播放完成')
							// 			if (that.isOnce) {
							// 				that.createAsr()
							// 				that.isOnce = false
							// 			}
							// 		}
							// 	}, 1000)
							// } else {

							// }
						}


						if (that.audio3) {
							that.audio3.muted = true
							if (window.WeixinJSBridge) {
								WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
									that.audio3.play();
								}, false);
							} else {
								document.addEventListener("WeixinJSBridgeReady", function() {
									WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
										that.audio3.play();
									});
								}, false);
							}
							that.audio3.play();
							that.audio3.muted = true
							that.audio3.volume = 0.0
						}
						let u = navigator.userAgent
						let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
						if(isIos){
							setTimeout(()=>{
								that.audio2.pause()
								that.audio3.pause()
							},1500)
						}
					}
				})
			},
			checkAsrEnd() {
				if (this.voice_text_str == '') {
					return false
				}
				this.isAsrStop = true
				this.localStreamAsr.stop();
				clearInterval(this.asrTimeDown)
				this.asrTimeDown = null
				const that = this
				let params = {
					nowContent: this.voice_text_str,
					dateTime: new Date().getTime()
				}
				if (this.verifyWay.length == 1) {
					params.type = this.verifyWay
				} else {
					params.type = this.moreVerifyWay
				}
				this.isVoice = 3
				params.sign = this.util.paramsFormat(params, that.userInfo.secretKey)
				this.api.checkAsr(params).then(res => {
					if (res.code == 200) {
						if (res.data && res.data == 4) {
							this.isLast = true
							this.$toast.success('校验成功')
							setTimeout(() => {
								that.$router.push({
									path: '/result',
									query: {
										status: 'success'
									}
								})
							}, 1500)
						} else if (res.data == 3 || res.data == 2) {
							this.isOnce = true
							this.$toast.success('校验成功')
							console.log(this.verifyWay)
							this.moreVerifyWay = this.verifyWay.split(',')[1]
							this.isVoice = 1
							this.step = 3
							let audio = that.audio3
							audio.muted = false
              audio.currentTime = 0
              setTimeout(() => {
                if (window.WeixinJSBridge) {
                  WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
                    audio.play();
                  }, false);
                } else {
                  document.addEventListener("WeixinJSBridgeReady", function() {
                    WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
                      audio.play();
                    });
                  }, false);
                }
                audio.play();
                audio.volume = 1.0

                audio.onended = function() {
                  console.log('播放完成')
                  if (that.isOnce) {
                    that.createAsr()
                    that.isOnce = false
                  }
                }
              },1000)

						}
					} else {
						if (res.code == 1900014 || res.code == 1900026) {
							this.voiceErrText = res.message
						} else {
							this.voiceErrText = '语音识别失败'
						}
						this.isVoice = 1
						this.voiceErr = true
						this.isReload = true
						if (!this.isMinute) {
							this.errorCountDown()
						}
						this.pause()
						clearInterval(that.auto)
						if (that.localStreamAsr) {
							that.isAsrStop = true
							that.localStreamAsr.stop()
							clearInterval(that.asrTimeDown)
							that.asrTimeDown = null
						}
					}
				}).catch(err => {
					if (err.data.code == 1900014 || err.data.code == 1900026) {
						this.voiceErrText = err.data.message
					} else if (err.data.code == 1900020 || err.data.code == 1900013) {
						this.$router.push({
							path: '/result',
							query: {
								status: 'error'
							}
						})
					} else {
						this.voiceErrText = '语音识别失败'
					}
					this.isVoice = 1
					this.voiceErr = true
					this.isReload = true
					if (!this.isMinute) {
						this.errorCountDown()
					}
					this.pause()
					clearInterval(that.auto)
					if (that.localStreamAsr) {
						that.isAsrStop = true
						that.localStreamAsr.stop()
						clearInterval(that.asrTimeDown)
						that.asrTimeDown = null
					}
				})
			},

			reload() {
				if (!this.isClick) {
					return false;
				}
				this.isClick = false
				const that = this
				clearInterval(that.isMinute)
				clearInterval(that.auto)
				if (that.localStreamAsr) {
					that.isAsrStop = true
					that.localStreamAsr.stop();
					clearInterval(that.asrTimeDown)
					that.asrTimeDown = null
				}
				if (that.step != 1) {
					that.isVoice = 1
				}

				that.isMinute = null
				that.play()
				that.voiceErr = false
				that.isError = false
				that.isReload = false
				that.isOnce = true
				that.isClick = true
				setTimeout(() => {
					that.takePhoto()
				}, 1200)
			},
			play() {
				const that = this
				if (that.step == 2 && that.isFirst) {
					return false
				}
				let audio = null
				if (that.step == 1) {
					audio = that.audio1
				} else if (that.step == 2) {
					audio = that.audio2
				} else if (that.step == 3) {
					audio = that.audio3
				}
				audio.muted = false
				audio.currentTime = 0

				setTimeout(() => {
					if (window.WeixinJSBridge) {
						WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
							audio.play();
						}, false);
					} else {
						document.addEventListener("WeixinJSBridgeReady", function() {
							WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
								audio.play();
							});
						}, false);
					}
					audio.play();
					audio.volume = 1.0

					audio.onended = function() {
						console.log('重播完成')
						if (that.step == 1) {
							if (that.isFirstAdd) {
								that.isVoiceOver = true
								that.isFirstAdd = false
								that.step = 2
							}
						} else {
							if (that.isOnce) {
								that.createAsr()
								that.isOnce = false
							}
						}
					}
				}, 1000)
			},
			pause() {
				const that = this
				let audio = null
				if (that.step == 1) {
					audio = that.audio1
				} else if (that.step == 2) {
					audio = that.audio2
				} else if (that.step == 3) {
					audio = that.audio3
				}
				if (window.WeixinJSBridge) {
					WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
						audio.pause()
					}, false);
				} else {
					document.addEventListener("WeixinJSBridgeReady", function() {
						WeixinJSBridge.invoke('getNetworkType', {}, function(e) {
							audio.pause()
						});
					}, false);
				}
				audio.pause()
			},
			// 压缩图片
			compress(img, callback) {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				//瓦片canvas
				let tCanvas = document.createElement("canvas");
				let tctx = tCanvas.getContext("2d");
				let ysImg = new Image()
				ysImg.src = img
				ysImg.onload = function() {
					let width = ysImg.width;
					let height = ysImg.height;
					//如果图片大于四百万像素，计算压缩比并将大小压至400万以下
					let ratio;
					if ((ratio = (width * height) / 1000000) > 1) {
						// console.log("大于400万像素");
						ratio = Math.sqrt(ratio);
						width /= ratio;
						height /= ratio;
					} else {
						ratio = 1;
					}
					canvas.width = width;
					canvas.height = height;
					//    铺底色
					ctx.fillStyle = "#fff";
					ctx.fillRect(0, 0, canvas.width, canvas.height);
					//如果图片像素大于100万则使用瓦片绘制
					let count;
					if ((count = (width * height) / 1000000) > 1) {
						// console.log("超过100W像素");
						count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
						//      计算每块瓦片的宽和高
						let nw = ~~(width / count);
						let nh = ~~(height / count);
						tCanvas.width = nw;
						tCanvas.height = nh;
						for (let i = 0; i < count; i++) {
							for (let j = 0; j < count; j++) {
								tctx.drawImage(ysImg, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw,
									nh);
								ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
							}
						}
					} else {
						ctx.drawImage(ysImg, 0, 0, width, height);
					}
					//进行最小压缩
					let ndata = canvas.toDataURL("image/jpeg", 0.9);
					tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
					callback(ndata)
				}
			},
			errorCountDown() {
				let jumpNum = 10
				let that = this
				that.isMinute = setInterval(() => {
					if (jumpNum == 0) {
						clearInterval(that.isMinute)
						that.$router.push({
							path: '/result',
							query: {
								status: 'error'
							}
						})
					} else {
						jumpNum--
					}
				}, 1000)
			},
			getAsrKeyInit() {
				let asrKeyData = {
					dateTime: new Date().getTime()
				}
				asrKeyData.sign = this.util.paramsFormat(asrKeyData, this.userInfo.secretKey)
				this.api.getAsrKey(asrKeyData).then(res => {
					if (res.code == 200) {
						this.asrKeyInfo = res.data
					}
				})
			},
			checkAsrTime() {
				let that = this
				that.nowProgress = 100
				let time = 40
				document.getElementById('progressLine').style.transition = 'all ' + time + 's linear 0s'
				this.asrTimeDown = setInterval(() => {
					if (time > 0) {
						time--
						if (that.nowProgress > 0) {
							that.nowProgress = 0
						}
					} else {
						that.$router.push({
							path: '/result',
							query: {
								status: 'error'
							}
						})
						clearInterval(that.asrTimeDown)
						that.asrTimeDown = null
					}
				}, 1000)
			}
		}
	}
</script>
<style lang="less" scoped>
	.page {
		height: 100%;
		overflow: hidden;
	}

	.page,
	.content,
	.localStream,
	.mainVedio,
	.userlist {
		height: 100%;
	}

	.progressBox {
		height: 20px;

		.progress {
			position: relative;
			height: 4px;
			background: #ebedf0;
			border-radius: 4px;

			.progressLine {
				position: absolute;
				left: 0;
				height: 100%;
				background: #5164FF;
				border-radius: inherit;
				width: 100%;
			}
		}
	}

	.left {
		position: fixed;
		width: 285px;
		height: 285px;
		border-radius: 50%;
		overflow: hidden;
		border: 4px solid #5164FF;
		left: calc((100% - 285px) / 2);
		top: 135px;
		box-sizing: border-box;
	}

	.left.errorBack {
		border-color: #FF3629;
	}

	.localStream {
		width: 100%;
		height: 100%;
		z-index: 9999;
		border-radius: 4px;
		position: relative;
	}

	.local-stream {
		width: 100%;
		height: 100%;
		position: relative;
	}

	.savePhoto {
		position: fixed;
		top: 0;
		left: 50%;
		z-index: 9;
	}

	.bottomBox {
		position: fixed;
		bottom: 0;
		left: 0;
		background-color: #f9f9f9;
		padding: 16px 24px 0;
		width: 100%;
		box-sizing: border-box;

		.textBox {
			text-align: left;

			.txt {
				font-size: 16px;
				color: #333;
				font-weight: bold;
				line-height: 24px;
			}

			.txt.tit {
				font-size: 14px;
				color: #666;
				font-weight: 500;
			}

			.txt:first-of-type {
				margin-bottom: 8px;
			}

			.txt:last-of-type {
				margin-bottom: 16px;
			}
		}
	}

	.errorText {
		position: fixed;
		top: 81px;
		left: 0;
		text-align: center;
		width: 100%;
		font-size: 18px;
		color: #333;
		font-weight: bold;
	}

	.voiceBox {
		text-align: center;
		font-size: 14px;
		color: #666;
		margin-top: 16px;
		padding-bottom: 8px;

		img {
			width: 24px;
			margin-top: 3px;
		}
	}

	.mask {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 9999999;
		display: flex;
		align-items: center;
		justify-content: center;

		.txt {
			color: #fff;
			font-size: 18px;
			font-weight: bold;
		}
	}

	@media (max-width:376px) {
		.left {
			top: 80px;
		}

		.errorText {
			top: 20px;
		}
	}
</style>
